// theme constant
export const gridSpacing = 3;
export const drawerWidth = 250;
// export const appDrawerWidth = 1000;

//new case entry point

export const addCaseEntry = "Add_Case_Entry";
export const getCase = "Get_Case";

export const addField = "Add_Field";
export const getField = "Get_Field";
export const deleteField = "Delete_Field";

export const addAccess = "Add_Access";
export const getAccess = "Get_Access";

// export const Permission = "Get_Permission"

//patients
export const addPatient = "Add_Patient";
export const updatePatient = "Update_Patient";
