import LocalizationProvider from "@mui/lab/LocalizationProvider";
import * as yup from "yup";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Wrapper from "components/Card/Wrapper";
import Required from "components/common/Required";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { DefaultRootState, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SNACKBAR_OPEN } from "store/actions";
import { gridSpacing } from "store/constant";
import Loader from "ui-component/Loader";
import axiosServices from "utils/axiosServices";
import { serializeValidData } from "utils/Helpers";
import langString from "utils/langString";
import DateTimePicker from "@mui/lab/DateTimePicker";
import TimePicker from "@mui/lab/TimePicker";
import * as Yup from "yup";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { addFieldReducer } from "store/vehicleFieldReducer";
import { indexOf } from "lodash";
import { RootState } from "types/vehicaleField";
import Switch from "@mui/material/Switch";
import { permission } from "utils/permission";

const PatientInformation = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [valueBasic, setValueBasic] = useState<any>();
  const [valueTime, setValueTime] = useState<Date>(new Date());
  const [zones, setZones] = useState<any[]>([]);
  const [vehicle, setVehicle] = useState<any[]>([]);
  const [vehicleValues, setVehicleValues] = useState<any>([]);
  const [details, setDetails] = useState<any>(null);
  const [vehicleDetails, setVehicleDetails] = useState<any>([]);
  const [upzilla, setUpzilla] = useState<any[]>([]);
  const [landmark, setLandmark] = useState<any[]>([]);
  const [caller, setCaller] = useState<any[]>([]);
  const param = useParams();
  const access = permission();

  const isEdit = () => {
    console.log("param", param.iid);
    return param.id === "null" ? false : true;
  };

  const isNotEditable = () => {
    return (
      access.includes("Patient-View") &&
      !access.includes("Patient-addExtraDetails")
    );
  };
  console.log("details", details);
  const getVehicleValues: any = useSelector(
    (state: RootState) => state.vehicleField
  );

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const postData =
          access.includes("Patient-addExtraDetails") &&
          !access.includes("Patient-Create")
            ? { uuid: param.id, incidentUuid: param.iid, outsider: true }
            : { uuid: param.id, incidentUuid: param.iid };

        const response = await axiosServices.post(`patient/get-all`, postData);
        if (response.status === 200) {
          setDetails(response.data.patientList[0]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    init();
  }, [param.id]);

  const schema = Yup.object().shape({
    cell_phone_number: yup
      .string()
      .matches(
        /^(?:(?:\+|00)88|01)?\d{11}$/,
        "phone number format is not correct"
      ),
    years: yup
      .number()
      .min(0, "Years cannot be negative")
      .max(100, "Years cannot be more than 100")
      .nullable(),

    months: yup
      .number()
      .min(0, "Months cannot be negative")
      .max(12, "Months cannot be more than 12")
      .nullable(),
  });
  return (
    <Wrapper
      title={`Hospital Patient Information Form `}
      backLink="/reports/incidents-report"
      showLogo={true}
    >
      {loading && <Loader />}
      <Formik
        enableReinitialize
        initialValues={{
          date: details?.arrival_date ? details.arrival_date : "",
          name_of_the_victim: details?.name || "",
          years: details?.age_year || null,
          months: details?.age_month || null,
          sex: details?.gender || "",
          cell_phone_number: details?.phone_number || "",
          first_aid: details?.treatment_at_scene === "Yes" ? true : false,
          dead: details?.brought_dead ? true : false,
          discharged: details?.discharged ? true : false,
          admitted: details?.admitted ? true : false,
          injury: details?.nature_of_injury || "",
          referred_hospital: details?.name_of_referred_hospital || "",
        }}
        validationSchema={!isNotEditable() ? schema : {}}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          console.log("values", values);

          const body = isEdit()
            ? {
                data: {
                  type: "Patients",
                  attributes: {
                    Patient: {
                      name: values.name_of_the_victim,
                      age_year: values.years,
                      age_month: values.months,
                      gender: values.sex,
                      treatmentAtScene: values.first_aid ? "Yes" : "No",
                      broughtDead: values.dead ? "Yes" : "No",
                      admitted: values.admitted ? "Yes" : "No",
                      discharged: values.discharged ? "Yes" : "No",
                      nameOfReferredHospital: values.referred_hospital,
                      natureOfInjury: values.injury,
                      phoneNumber: values.cell_phone_number,
                      arrivalDate: values.date,
                      uuid: param.id,
                    },
                    incident: { uuid: param.iid },
                  },
                },
              }
            : {
                data: {
                  type: "Patients",
                  attributes: {
                    Patient: {
                      name: values.name_of_the_victim,
                      age_year: values.years,
                      age_month: values.months,
                      gender: values.sex,
                      treatmentAtScene: values.first_aid ? "Yes" : "No",
                      broughtDead: values.dead,
                      admitted: values.admitted,
                      discharged: values.discharged,
                      nameOfReferredHospital: values.referred_hospital,
                      natureOfInjury: values.injury,
                      phoneNumber: values.cell_phone_number,
                      arrivalDate: values.date,
                    },
                    incident: { uuid: param.iid },
                  },
                },
              };

          try {
            setLoading(true);

            const response = await axiosServices.post(
              `patient/infoFromHospital`,
              body
            );
            if (response.status === 200) {
              dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: isEdit()
                  ? "Patient Updated Successfully"
                  : "Patient Added Successfully",
                variant: "alert",
                alertSeverity: "success",
              });
              navigate(-1);
            }
            setLoading(false);
          } catch (err: any) {
            dispatch({
              type: SNACKBAR_OPEN,
              open: true,
              message: isEdit()
                ? "Patient Update Failed"
                : "Patient Add Failed",
              variant: "alert",
              alertSeverity: "error",
            });
            setStatus({ success: false });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setFieldValue,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            <>
              <Paper elevation={2} sx={{ padding: 3, margin: 2 }}>
                <Grid container justifyContent="flex-start" direction="column">
                  <Typography sx={{ marginBottom: 4 }} variant="h2">
                    Hospital Patient Information
                  </Typography>

                  <Grid
                    container
                    spacing={4}
                    sx={{ mb: "20px" }}
                    alignItems="center"
                  >
                    <Grid item xs={6} md={2}>
                      <Typography variant="h5" sx={{ textAlign: "left" }}>
                        Date
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DateTimePicker
                          renderInput={(props: any) => (
                            <TextField
                              name="date"
                              fullWidth
                              {...props}
                              disabled={isNotEditable()}
                            />
                          )}
                          label="Date & Time"
                          value={values.date}
                          onChange={(e: any) => {
                            console.log("date", e);
                            setFieldValue("date", e);
                          }}
                        />
                      </LocalizationProvider>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={4}
                    sx={{ mb: "20px", alignItems: "center" }}
                  >
                    <Grid item xs={6} md={2}>
                      <Typography variant="h5" sx={{ textAlign: "left" }}>
                        Name of the Victim
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        error={
                          errors.name_of_the_victim &&
                          touched.name_of_the_victim
                            ? true
                            : false
                        }
                        name="name_of_the_victim"
                        fullWidth
                        disabled={isNotEditable()}
                        label="Name of the victim"
                        helperText={
                          errors.name_of_the_victim &&
                          touched.name_of_the_victim
                            ? errors.name_of_the_victim
                            : ""
                        }
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={
                          values.name_of_the_victim
                            ? values.name_of_the_victim
                            : ""
                        }
                      ></TextField>
                      {console.log(touched, errors)}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={4}
                    sx={{ mb: "20px", alignItems: "center" }}
                  >
                    <Grid item xs={4} md={2}>
                      <Typography variant="h5" sx={{ textAlign: "left" }}>
                        Age
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Grid container item alignItems="center">
                        {" "}
                        <TextField
                          id="standard-number"
                          error={
                            (errors.years || errors.months) &&
                            (touched.years || touched.months)
                              ? true
                              : false
                          }
                          label="Year"
                          disabled={isNotEditable()}
                          type="number"
                          inputProps={{ min: 0, max: 100 }}
                          variant="outlined"
                          name="years"
                          sx={{ mr: "10px" }}
                          onChange={(e: any) => {
                            e.target.value === ""
                              ? setFieldValue("years", null)
                              : setFieldValue("years", e.target.value);
                          }}
                          onBlur={handleBlur}
                          value={values.years ? values.years : ""}
                        />
                        <Typography sx={{ mr: "50px" }}> Year</Typography>
                        <TextField
                          error={
                            (errors.years || errors.months) &&
                            (touched.years || touched.months)
                              ? true
                              : false
                          }
                          id="standard-number"
                          label="Months"
                          type="number"
                          name="months"
                          disabled={isNotEditable()}
                          inputProps={{ min: 0, max: 12 }}
                          variant="outlined"
                          sx={{ mr: "10px" }}
                          onChange={(e: any) => {
                            e.target.value === ""
                              ? setFieldValue("months", null)
                              : setFieldValue("months", e.target.value);
                          }}
                          onBlur={handleBlur}
                          value={values.months ? values.months : ""}
                        />
                        <Typography> Months</Typography>
                      </Grid>
                      {(touched.years || touched.months) &&
                        (errors.years || errors.months) && (
                          <Typography
                            variant="caption"
                            sx={{ ml: 1.5 }}
                            color="red"
                          >
                            {errors.years} {errors.months}
                          </Typography>
                        )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={4}
                    sx={{ mb: "20px", alignItems: "center" }}
                  >
                    <Grid item xs={4} md={2}>
                      <Typography variant="h5" sx={{ textAlign: "left" }}>
                        Gender
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={
                          values.sex === ""
                            ? ""
                            : values.sex === "Male" || values.sex === "male"
                            ? "Male"
                            : "Female"
                        }
                        name="sex"
                        onChange={handleChange}
                      >
                        <FormControlLabel
                          value="Female"
                          control={<Radio />}
                          label="Female"
                          disabled={isNotEditable()}
                        />
                        <FormControlLabel
                          value="Male"
                          control={<Radio />}
                          label="Male"
                          disabled={isNotEditable()}
                        />
                      </RadioGroup>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={4}
                    sx={{ mb: "20px", alignItems: "center" }}
                  >
                    <Grid item xs={4} md={2}>
                      <Typography variant="h5" sx={{ textAlign: "left" }}>
                        Cell Phone Number (if available)
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        error={
                          errors.cell_phone_number && touched.cell_phone_number
                            ? true
                            : false
                        }
                        fullWidth
                        disabled={isNotEditable()}
                        name="cell_phone_number"
                        label="Cell Phone Number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.cell_phone_number}
                        helperText={
                          errors.cell_phone_number && touched.cell_phone_number
                            ? errors.cell_phone_number
                            : ""
                        }
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    spacing={4}
                    sx={{ mb: "20px", alignItems: "center" }}
                  >
                    <Grid item xs={6} md={2}>
                      <Typography
                        variant="h5"
                        sx={{ marginTop: 2, textAlign: "left" }}
                      >
                        Nature of the injuries : (Description of injuries e.g -
                        Head injury, fracture femur, amputation at wrist joine,
                        abrassion, laceration etc):
                      </Typography>{" "}
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        error={errors.injury && touched.injury ? true : false}
                        name="injury"
                        fullWidth
                        disabled={isNotEditable()}
                        label="Nature of the Injury"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.injury}
                      ></TextField>
                    </Grid>
                  </Grid>

                  <Grid container spacing={4} sx={{ mb: "20px" }}>
                    <Grid item xs={6} md={2}>
                      <Typography variant="h5" sx={{ textAlign: "left" }}>
                        First Aid given by the volunteers at the spot
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Grid container alignItems="center">
                        <Typography>No</Typography>
                        <Switch
                          checked={values.first_aid}
                          name="first_aid"
                          onChange={handleChange}
                          disabled={isNotEditable()}
                        />
                        <Typography>Yes</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={4} sx={{ mb: "20px" }}>
                    <Grid item xs={6} md={2}>
                      <Typography variant="h5" sx={{ textAlign: "left" }}>
                        Brought dead in the Hospital
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Grid container alignItems="center">
                        <Typography>No</Typography>
                        <Switch
                          checked={values.dead}
                          name="dead"
                          onChange={handleChange}
                          defaultChecked
                          disabled={isNotEditable()}
                        />
                        <Typography>Yes</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={4} sx={{ mb: "20px" }}>
                    <Grid item xs={6} md={2}>
                      <Typography variant="h5" sx={{ textAlign: "left" }}>
                        Discharged after primary treatment
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Grid container alignItems="center">
                        <Typography>No</Typography>
                        <Switch
                          checked={values.discharged}
                          name="discharged"
                          onChange={handleChange}
                          disabled={isNotEditable()}
                        />
                        <Typography>Yes</Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid container spacing={4} sx={{ mb: "20px" }}>
                    <Grid item xs={6} md={2}>
                      <Typography variant="h5" sx={{ textAlign: "left" }}>
                        Admitted in the hospital
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <Grid container alignItems="center">
                        <Typography>No</Typography>
                        <Switch
                          checked={values.admitted}
                          name="admitted"
                          onChange={handleChange}
                          disabled={isNotEditable()}
                        />
                        <Typography>Yes</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4} sx={{ mb: "20px" }}>
                    <Grid item xs={6} md={2}>
                      <Typography variant="h5" sx={{ textAlign: "left" }}>
                        Referred to another hospital (Please mention the name of
                        the hospital):
                      </Typography>
                    </Grid>
                    <Grid item xs={6} md={6}>
                      <TextField
                        fullWidth
                        value={values.referred_hospital}
                        name="referred_hospital"
                        disabled={isNotEditable()}
                        onChange={handleChange}
                        label="Name of the hospital"
                      ></TextField>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
              {/* {errors.submit && (
                  <Box sx={{ mt: 3 }}>
                    <FormHelperText error>{errors.submit}</FormHelperText>
                  </Box>
                )} */}
              {!isNotEditable() && (
                <Box sx={{ textAlign: "right", paddingTop: 3 }}>
                  <Button
                    color="warning"
                    disabled={isSubmitting}
                    size="large"
                    type="button"
                    onClick={() =>
                      navigate("/reports/incidents-report", { replace: true })
                    }
                    variant="contained"
                    sx={{ marginRight: 2 }}
                  >
                    {langString("cancel")}
                  </Button>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {langString("save")}
                  </Button>
                </Box>
              )}
            </>
          </form>
        )}
      </Formik>
    </Wrapper>
  );
};

export default PatientInformation;
