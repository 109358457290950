import { permission } from "./../utils/permission";
// action - state management
import { LOGIN, LOGOUT, REGISTER } from "./actions";
import { initialLoginContextProps } from "types";

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState: initialLoginContextProps = {
  isLoggedIn: false,
  isInitialized: false,
  user: {},
  avater: "text",
};

export interface AccountReducerActionProps {
  type: string;
  payload?: initialLoginContextProps;
}

export const accountReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case REGISTER: {
      const { user } = action.payload!;

      return {
        ...state,
        user: user,
      };
    }
    case LOGIN: {
      const { user } = action.payload;
      console.log("user", action.payload.user.permissions);

      return {
        ...state,
        isLoggedIn: true,
        isInitialized: true,
        user: action.payload.user,
      };
    }
    // case "TEST": {
    //   console.log(state);
    //   return {
    //     ...state,
    //     avater: "helloooooo",
    //   };
    // }
    case LOGOUT: {
      console.log(action.payload);
      return {
        ...state,
        isInitialized: true,
        isLoggedIn: false,
        user: null,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default accountReducer;
