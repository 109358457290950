import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Wrapper from "components/Card/Wrapper";
import BasicDataTable, { ColumnType } from "components/common/BasicDataTable";
import ConfirmButton from "components/common/ConfirmButton";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SNACKBAR_OPEN } from "store/actions";
import Loader from "ui-component/Loader";
import axiosServices from "utils/axiosServices";
import { serializeValidData } from "utils/Helpers";
import langString from "utils/langString";
import SearchIcon from "@mui/icons-material/Search";
import { RootState } from "types/vehicaleField";
import { permission } from "utils/permission";

export default function ServiceList() {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [startIndex, setStartIndex] = useState(0);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState<string>("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const access = permission();

  const Columns: ColumnType[] = [
    // {
    //   header: "SL No",
    //   accessor: "id",
    //   content: (item: any) => (
    //     <Typography>{rows.indexOf(item) + startIndex + 1}</Typography>
    //   ),
    // },
    {
      header: "Landmark ID",
      accessor: "id",
      content: (item: any) => <Typography>{item.id}</Typography>,
    },
    {
      header: "Landmark Name",
      accessor: "landmark_name",
      content: (item: any) => <Typography>{item.landmark_name}</Typography>,
    },
    {
      header: "Zone",
      accessor: "zone",
      content: (item: any) => <Typography>{item.zone_name}</Typography>,
    },
    {
      header: "Latitude",
      accessor: "latitude",
      content: (item: any) => <Typography>{item.landmark_latitude}</Typography>,
    },
    {
      header: "Longitude",
      accessor: "longitude",
      content: (item: any) => (
        <Typography>{item.landmark_longitude}</Typography>
      ),
    },

    {
      header: "Action",
      accessor: "action",
      content: (item: any) => (
        <Box display="flex" sx={{ justifyContent: "space-around" }}>
          {/* <Button
            component={Link}
            variant="contained"
            color="primary"
            to={`/service-details/${item.id}`}
            size="small"
          >
            View
          </Button> */}
          {access.includes("Landmark-Edit") && (
            <Button
              component={Link}
              variant="contained"
              color="secondary"
              to={`/public/landmark/edit/${item.landmark_uuid}`}
              size="small"
              sx={{ marginLeft: 0.5 }}
            >
              Edit
            </Button>
          )}
          {access.includes("Landmark-Delete") && (
            <ConfirmButton
              subTitle={`Delete Lankmark Location: ${item.landmark_name}`}
              confirmed={() => deleteListItem(item.landmark_uuid)}
            />
          )}
        </Box>
      ),
    },
  ];

  const deleteListItem = async (id: string) => {
    if (id) {
      setLoading(false);
      try {
        const postData = serializeValidData("landmarks", {
          landmark: { uuid: id },
        });
        console.log(postData);
        const response = await axiosServices.post(`landmark/delete`, postData);
        if (response.status === 200) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: "Landmark Deleted successfully.",
            variant: "alert",
            alertSeverity: "success",
          });
          const allRows = [...rows];
          const filtered = allRows.filter(
            (item: any) => item.landmark_uuid !== id
          );
          setRows(filtered);
          setLoading(false);
          navigate("/public/landmark", { replace: true });
        }
      } catch (error: any) {
        setLoading(false);
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: "This landmark is used",
          variant: "alert",
          alertSeverity: "error",
        });
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const response = await axiosServices.post(
          `landmark/get-all?limit=${rowsPerPage}&currentPage=${page + 1}`
        );
        if (response.status === 200) {
          if (response.data) {
            setCount(response.data.paginationInfo.totalData);
            setRows(response.data?.landmarkList);
            setStartIndex(response.data?.paginationInfo.startIndex);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    init();
  }, [page, rowsPerPage]);

  const handleSearch = (value: string) => {
    setSearch(value);
    const body = { searchString: value };
    axiosServices
      .post("landmark/search", body)
      .then((response) => {
        setCount(response.data.paginationInfo.totalData);
        setRows(response.data?.landmarkList);
        setStartIndex(response.data?.paginationInfo.startIndex);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Wrapper
      title={langString("Landmark")}
      addLink="/public/landmark/add"
      permission={access.includes("Landmark-Create")}
    >
      {loading && <Loader />}
      <Box sx={{ marginTop: 1 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ marginBottom: 2, textAlign: "right" }}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search Landmark"
              size="small"
            />
          </Grid>
        </Grid>
        {access.includes("Landmark-View") ? (
          <BasicDataTable
            columns={Columns}
            rows={rows}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={(value: number) => setPage(value)}
            setRowsPerPage={(value: number) => setRowsPerPage(value)}
          />
        ) : (
          <Typography variant="h1" align="center" color="error">
            Access Denied !
          </Typography>
        )}
      </Box>
    </Wrapper>
  );
}
