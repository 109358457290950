import { Button, Grid, Typography, CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
import langString from "utils/langString";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import AddBoxIcon from "@mui/icons-material/AddBox";
import MainCard from "ui-component/cards/MainCard";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import Logo from "../../assets/images/icons/logo.png";
import { CSVLink, CSVDownload } from "react-csv";
import { LoadingButton } from "@mui/lab";
// import { zoneAdd } from "utils/permission";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import axiosServices from "utils/axiosServices";
import { SNACKBAR_OPEN } from "store/actions";

type WrapperPropsType = {
  title: string;
  backLink?: string;
  addLink?: string;
  children: React.ReactNode;
  addComponent?: React.ReactNode;
  editLink?: string;
  permission?: boolean;
  showLogo?: boolean;
  IncidentData?: any;
  PatientData?: any;
};

export default function Wrapper({
  title,
  backLink,
  addLink,
  children,
  addComponent,
  editLink,
  permission,
  IncidentData,
  PatientData,
  showLogo,
}: WrapperPropsType) {
  const [loading, setLoading] = useState<any>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <MainCard sx={{ minHeight: "80vh" }}>
      <Grid
        container
        justifyContent={"space-between"}
        sx={{ borderBottom: "1px solid #DDD", marginBottom: 2 }}
      >
        <Grid item xs={8} sm={8} lg={8} xl={8}>
          {showLogo && <img height={100} src={Logo} />}
          <Typography variant="h3" padding={1} color="#0D0156">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} lg={4} xl={4} textAlign="right">
          {permission && editLink && (
            <Button
              sx={{ float: "right", marginLeft: 2 }}
              size="small"
              component={Link}
              to={editLink}
              variant="contained"
              color="primary"
            >
              <BorderColorIcon />
              <Typography sx={{ marginLeft: 1 }}>Edit</Typography>
            </Button>
          )}
          {backLink && (
            <Button
              sx={{ float: "right", marginLeft: 2 }}
              size="small"
              component={Link}
              to=""
              onClick={() => {
                navigate(-1);
              }}
              variant="contained"
              color="info"
            >
              <ArrowBackIosIcon />
              {langString("back")}
            </Button>
          )}
          {permission && addLink && (
            <Button
              sx={{ float: "right" }}
              size="small"
              component={Link}
              to={addLink}
              variant="contained"
              color="primary"
            >
              <AddBoxIcon />
              {langString("add")}
            </Button>
          )}
          {addComponent && <>{addComponent}</>}

          <div style={{ display: "flex", flexDirection: "row" }}>
            {" "}
            {IncidentData && (
              <Button
                size="small"
                color="warning"
                variant="contained"
                disabled={loading}
              >
                {loading && <CircularProgress size={20} />}
                {!loading && (
                  <>
                    <FileDownloadIcon />
                    {IncidentData && (
                      <CSVLink
                        data={IncidentData}
                        filename={"IncidentData.csv"}
                        style={{
                          textDecoration: "none",
                          color: "black",
                          fontSize: "10px",
                        }}
                      >
                        {langString("Download Incident Information")}
                      </CSVLink>
                    )}
                  </>
                )}
              </Button>
            )}
            {PatientData && (
              <Button
                size="small"
                color="info"
                variant="contained"
                disabled={loading}
                sx={{ ml: 5 }}
              >
                {loading && <CircularProgress size={20} />}
                {!loading && (
                  <>
                    <FileDownloadIcon />
                    {IncidentData && (
                      <CSVLink
                        data={PatientData}
                        filename={"PatientData.csv"}
                        style={{
                          textDecoration: "none",
                          color: "white",
                          fontSize: "10px",
                        }}
                      >
                        {langString("Download Patient Information")}
                      </CSVLink>
                    )}
                  </>
                )}
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={12} lg={12} xl={12} sx={{ padding: 1 }}>
          {children}
        </Grid>
      </Grid>
    </MainCard>
  );
}
