import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Wrapper from "components/Card/Wrapper";
import FileInput from "components/common/FileInput";
import Required from "components/common/Required";
import { Formik } from "formik";
import { Deserializer } from "jsonapi-serializer";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SNACKBAR_OPEN } from "store/actions";
import { gridSpacing } from "store/constant";
import Loader from "ui-component/Loader";
import axiosServices from "utils/axiosServices";
import {
  serviceDependencies,
  serviceTypes,
} from "utils/Constants";
import { serializeValidData } from "utils/Helpers";
import langString from "utils/langString";
import * as Yup from "yup";
import landmark from ".";

export default function EditService() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [details, setDetails] = useState<any>(null);
  const [zones, setZones] = useState<any[]>([]);
  const param = useParams();

useEffect(() => {
  const init = async () => {
    setLoading(true);
    try {
      const response = await axiosServices.post(
        `zone/get-all`
      );
      if (response.status === 200) {
        setZones(response.data?.zoneList)
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  init();
}, []);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const postData = { uuid: param.id }
        const response = await axiosServices.post(
          `landmark/get-all`,
          postData
        );
        if (response.status === 200) {
          setDetails(response.data.landmarkList[0]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    init();
  }, [param.id]);


  return (
    <Wrapper
      title={`${langString("edit")} ${langString("landmark")}`}
      backLink="/public/landmark"
    >
      {loading && <Loader />}
      {details && (
        <Formik
          initialValues={{
            name: details.landmark_name,
            latitude: details.landmark_latitude,
            longitude: details.landmark_longitude,
            zone: details.zone_uuid,
            description: details.landmark_description,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required"),
            longitude: Yup.string().required("Longitude is required"),
            latitude: Yup.string().required("Latitude is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            const body: any = {
              uuid:details.landmark_uuid,
              name: values.name,
              latitude: values.latitude,
              longitude: values.longitude,
              zone: values.zone,
              description: values.description,
            };

            try {
              setLoading(true);
              const postData = serializeValidData("landmarks", {landmark:body});
              const response = await axiosServices.post(
                "landmark/update",
                postData
              );
              if (response.status === 200) {
                dispatch({
                  type: SNACKBAR_OPEN,
                  open: true,
                  message: "Landmark Updated Successfully",
                  variant: "alert",
                  alertSeverity: "success",
                });
                navigate("/public/landmark", { replace: true });
              }
              setLoading(false);
            } catch (err: any) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
            <Box sx={{ border: "1px solid #EEE", padding: 3, borderRadius: 3 }}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={6} md={3}>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: 2, textAlign: "right" }}
                  >
                    Landmark Name <Required />
                  </Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                  >
                    <TextField
                      id="outlined-adornment-name-user-create"
                      type="text"
                      label={"Landmark Name"}
                      value={values.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-name-user-create"
                      >
                        {errors.name}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={gridSpacing} sx={{ marginTop: "5px" }}>
                <Grid item xs={6} md={3}>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: 2, textAlign: "right" }}
                  >
                    {langString("Description")}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-adornment-name-user-create"
                      name="description"
                      type="text"
                      label={langString("Description")}
                      value={values.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={gridSpacing} sx={{ marginTop: "5px" }}>
                <Grid item xs={6} md={3}>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: 2, textAlign: "right" }}
                  >
                    {langString("Location Mark")} <Required />
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      label={langString("Latitude")}
                      id="outlined-adornment-quantity-user-create"
                      name="latitude"
                      type="text"
                      value={values.latitude}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                    />
                    {touched.latitude && errors.latitude && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-name-user-create"
                      >
                        {errors.latitude}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      label={langString("Longitude")}
                      id="outlined-adornment-quantity-user-create"
                      name="longitude"
                      type="text"
                      value={values.longitude}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                    />

                    {touched.longitude && errors.longitude && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-name-user-create"
                      >
                        {errors.longitude}
                      </FormHelperText>
                    )}

                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={gridSpacing} sx={{ marginTop: "5px" }}>
                <Grid item xs={6} md={3}>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: 2, textAlign: "right" }}
                  >
                    {langString("Zone")}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  <FormControl
                    fullWidth
                  >
                    <InputLabel id="dependson-select-label">
                      {langString("zone")}
                    </InputLabel>
                    <Select
                      name="zone"
                      id="dependson-select"
                      fullWidth
                      label={langString("Zone")}
                      value={values.zone}
                      onChange={handleChange}
                    >
                      {
                        zones.map((item: { uuid: string | number | readonly string[] | undefined; name: any; }) =><MenuItem value={item.uuid}>{langString(`${item.name}`)}</MenuItem>)
                      }
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>


              <Grid container spacing={gridSpacing} sx={{ marginTop: "5px" }}>


              </Grid>

              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box sx={{ textAlign: "right", paddingTop: 3 }}>
                <Button
                  color="warning"
                  disabled={isSubmitting}
                  size="large"
                  type="button"
                  onClick={() => navigate("/public/landmark", { replace: true })}
                  variant="contained"
                  sx={{ marginRight: 2 }}
                >
                  {langString("cancel")}
                </Button>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {langString("save")}
                </Button>
              </Box>
            </Box>
          </form>
          )}
        </Formik>
      )}
    </Wrapper>
  );
}
