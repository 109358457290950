import { useParams } from "react-router-dom";
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Wrapper from "components/Card/Wrapper";
import BasicDataTable, { ColumnType } from "components/common/BasicDataTable";
import ConfirmButton from "components/common/ConfirmButton";
import { Deserializer } from "jsonapi-serializer";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SNACKBAR_OPEN } from "store/actions";
import Loader from "ui-component/Loader";
import axiosServices from "utils/axiosServices";
import { serializeValidData } from "utils/Helpers";
import SearchIcon from "@mui/icons-material/Search";
import langString from "utils/langString";
import { gridSpacing } from "store/constant";
import { RootState } from "types/vehicaleField";
import { permission } from "utils/permission";
import componentStyleOverrides from "themes/compStyleOverride";

const IncidentProfile = () => {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState<string>("");
  const [details, setDetails] = useState<any>([]);
  const [startIndex, setStartIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const access = permission();
  const param = useParams();
  const route = useRef<any>();
  console.log("route", route);
  const incidentSL = useParams().SL;

  const Columns: ColumnType[] = [
    {
      header: "SL No",
      accessor: "id",
      content: (item: any) => (
        <Typography>{rows.indexOf(item) + startIndex + 1}</Typography>
      ),
    },
    {
      header: "ID",
      accessor: "id",
      content: (item: any) => <Typography>{item.patient_id}</Typography>,
    },
    { header: "Name", accessor: "name" },
    {
      header: "Age",
      accessor: "age",
      content: (item: any) => <Typography>{item.age_year}</Typography>,
    },
    {
      header: "Gender",
      accessor: "Gender",
      content: (item: any) => (
        <Typography>
          {item?.gender
            ? item.gender.charAt(0).toUpperCase() + item.gender.slice(1)
            : ""}
        </Typography>
      ),
    },
    {
      header: "Injury Type",
      accessor: "injuryType",
      content: (item: any) => <Typography>{item.injury_severity}</Typography>,
    },
    {
      header: "Hospital Type",
      accessor: "hospital",
      content: (item: any) => <Typography>{item.hospital_type}</Typography>,
    },
    {
      header: "Action",
      accessor: "action",
      content: (item: any) => (
        <Box display="flex" sx={{ justifyContent: "space-around" }}>
          {access.includes("Patient-View") && (
            <Button
              component={Link}
              variant="contained"
              color="primary"
              to={`/incident-report/profile/${param.id}/patient-view/${
                item.uuid
              }/${incidentSL}/${rows.indexOf(item) + startIndex + 1}`}
              size="small"
            >
              View
            </Button>
          )}

          {access.includes("Patient-Edit") && (
            <Button
              component={Link}
              variant="contained"
              color="secondary"
              to={`/incident-report/profile/${param.id}/patient-form-edit/${item.uuid}`}
              size="small"
              sx={{ marginLeft: 0.5 }}
            >
              Edit
            </Button>
          )}
          {(access.includes("Patient-addExtraDetails") ||
            (access.includes("Patient-View") &&
              !access.includes("Patient-addExtraDetails"))) && (
            <Button
              component={Link}
              variant="contained"
              color="warning"
              to={`/incident-report/profile/${param.id}/hospital-patient-info/${item.uuid}`}
              size="small"
              sx={{ marginLeft: 0.5 }}
            >
              {access.includes("Patient-View") &&
              !access.includes("Patient-addExtraDetails")
                ? "Show more info"
                : "Add more info"}
            </Button>
          )}
          {access.includes("Patient-Delete") && (
            <ConfirmButton
              subTitle={`Delete Patient Location: ${item.name}`}
              confirmed={() => deleteListItem(item.uuid)}
            />
          )}
        </Box>
      ),
    },
  ];

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const postData =
          access.includes("Patient-addExtraDetails") &&
          !access.includes("Patient-Create")
            ? { incidentUuid: param.id, outsider: true }
            : { incidentUuid: param.id };
        const response = await axiosServices.post(
          `patient/get-all?limit=${rowsPerPage}&currentPage=${page + 1}`,
          postData
        );
        if (response.status === 200) {
          if (response.data) {
            setCount(response.data.paginationInfo?.totalData);
            setRows(response.data?.patientList);
            setStartIndex(response.data?.paginationInfo.startIndex);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    init();
  }, [page, rowsPerPage]);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const postData = { uuid: param.id };

        const response = await axiosServices.post(`incident/get-all`, postData);
        if (response.status === 200) {
          setDetails(response.data.incidentList[0]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    init();
    returnRoute();
  }, [param.id]);

  const returnRoute = () => {
    if (
      access.includes("Patient-addExtraDetails") &&
      !access.includes("Patient-Create")
    ) {
      route.current = `/incident-report/profile/${param.id}/hospital-patient-info/null`;
    } else if (access.includes("Patient-Create")) {
      route.current = `/incident-report/profile/${param.id}/patient-form`;
    }
  };

  const handleSearch = (value: string) => {
    setSearch(value);
    const body = { searchString: value };
    axiosServices
      .post("patient/search", body)
      .then((response) => {
        setCount(response.data.paginationInfo.totalData);
        setRows(response.data?.patientList);
        setStartIndex(response.data?.paginationInfo.startIndex);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteListItem = async (uuid: string) => {
    setLoading(true);
    try {
      const postData = serializeValidData("Patient", {
        Patient: { uuid: uuid },
      });
      const response = await axiosServices.post(`patient/delete`, postData);
      if (response.status === 200) {
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: "Patient deleted successfully.",
          variant: "alert",
          alertSeverity: "success",
        });
        const allRows = [...rows];
        const filtered = allRows.filter((item: any) => item.uuid !== uuid);

        setRows(filtered);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      console.log(error);
      if (error) {
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: "Patient Delete Failed",
          variant: "alert",
          alertSeverity: "error",
        });
      }
    }
  };

  return (
    <Wrapper
      title={`Incident no. ${param.SL} Profile`}
      addLink={route.current}
      backLink={`/reports/incidents-report`}
      permission={
        access.includes("Patient-Create") ||
        access.includes("Patient-addExtraDetails")
      }
    >
      {loading && <Loader />}

      <Paper
        elevation={4}
        sx={{
          display: "inline-block",
          padding: 3,
          my: 2,
          width: "100%",
          overflow: "auto",
        }}
      >
        <Grid container spacing={gridSpacing} sx={{ marginTop: "5px" }}>
          <Grid
            xs={6}
            md={6}
            item
            maxWidth="100%"
            display="flex"
            alignItems="center"
          >
            <Typography variant={"h3"}>Incident Id : </Typography>
            <Typography variant="h4" sx={{ ml: 2, color: "#357e2a" }}>
              {" "}
              {details.em_id}
            </Typography>
          </Grid>
          <Grid
            xs={6}
            md={6}
            maxWidth="100%"
            alignItems="center"
            alignContent="flex-start"
            item
            display="flex"
          >
            <Typography variant="h3" align="left">
              Zone Name :{" "}
            </Typography>
            <Typography variant="h4" sx={{ ml: 2, color: "#357e2a" }}>
              {" "}
              {details.zone_name}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={gridSpacing} sx={{ marginTop: "10px" }}>
          <Grid
            xs={6}
            md={6}
            item
            maxWidth="100%"
            alignItems="center"
            display="flex"
          >
            <Typography variant="h3">Injured Patients : </Typography>
            <Typography variant="h4" sx={{ ml: 2, color: "#357e2a" }}>
              {" "}
              {details.injury_count}
            </Typography>
          </Grid>
          <Grid alignItems="center" xs={6} md={6} item display="flex">
            <Typography variant="h3" align="left">
              Location of Crash :{" "}
            </Typography>

            <Typography variant="h4" sx={{ ml: 2, color: "#357e2a" }}>
              {" "}
              {details.landmark_name}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={gridSpacing} sx={{ marginTop: "10px" }}>
          <Grid
            item
            maxWidth="100%"
            alignItems="center"
            xs={6}
            md={6}
            display="flex"
          >
            <Typography variant="h3">Caller:</Typography>
            <Typography variant="h4" sx={{ ml: 2, color: "#357e2a" }}>
              {details.caller}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Box sx={{ marginTop: 1 }}>
        <Grid container>
          <Grid
            item
            maxWidth="100%"
            xs={12}
            sm={12}
            sx={{ marginBottom: 2, textAlign: "right" }}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search Patient"
              size="small"
            />
          </Grid>
        </Grid>
        <BasicDataTable
          columns={Columns}
          rows={rows}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          setPage={(value: number) => setPage(value)}
          setRowsPerPage={(value: number) => setRowsPerPage(value)}
        />
      </Box>
    </Wrapper>
  );
};

export default IncidentProfile;
