import MainLayout from "layout/MainLayout";
import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import Calldata from "views/CallData/Calldata";
import Landmark from "../views/location/landmark/index";
import LandmarkAdd from "../views/location/landmark/Add";
import LandmarkEdit from "../views/location/landmark/Edit";
import Zone from "../views/location/Zone/index";
import ZoneAdd from "../views/location/Zone/Add";
import ZoneEdit from "../views/location/Zone/Edit";
import Hospital from "../views/hospital/index";
import HospitalAdd from "../views/hospital/Add";
import HospitalEdit from "../views/hospital/Edit";
import Responder from "../views/Responder/index";
import ResponderAdd from "../views/Responder/Add";
import ResponderEdit from "../views/Responder/Edit";
import Reports from "../views/reports/index";
import Users from "../views/userManage/index";
import AddUserType from "../views/userManage/AddUserType";
import AccessControl from "views/userManage/AccessControl";
import ResponseTime from "views/reports/Report/ResponseTime";
import PatientTime from "views/reports/Report/PatientTime";
import TimeCrush from "views/reports/Report/TimeCrush";
import InjuryAsse from "views/reports/Report/InjuryAsse";
import ViewRole from "views/userManage/Role/index";
import AddRole from "views/userManage/Role/Add";
import EditRole from "views/userManage/Role/Edit";
import UpdateUser from "views/userManage/UpdateUser";
import IncidentForm from "views/reports/IncidentForm";
import PatientForm from "views/reports/PatientForm";
import IncidentProfile from "views/reports/IncidentProfile";
import PatientFormEdit from "views/reports/PatientFormEdit";
import IncidentView from "views/reports/IncidentView";
import IncidentFormEdit from "views/reports/IncidentFormEdit";
import TypeOfControl from "views/userManage/TypeOfControl";
import PatientView from "views/reports/PatientView";
import Notfound from "views/Notfound/Notfound";
import { permission } from "utils/permission";
import PatientInformation from "views/reports/PatientInformation";

// sample page routing
const Homepage = Loadable(lazy(() => import("views/homepage")));
const access = permission();
// ==============================|| MAIN ROUTING ||============t================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/",
      element: <Homepage />,
    },
    {
      path: "/emergency-call",
      element: <Calldata />,
    },
    {
      path: "/reports/incidents-report",
      element: <Reports />,
    },
    {
      path: "/reports/response-times",
      element: <ResponseTime />,
    },
    {
      path: "/reports/patient-age-gender",
      element: <PatientTime />,
    },
    {
      path: "/reports/time-from-crash-hospital",
      element: <TimeCrush />,
    },
    {
      path: "/reports/injury-assessment",
      element: <InjuryAsse />,
    },
    {
      path: "/public/landmark",
      element: <Landmark />,
    },
    {
      path: "/public/landmark/add",
      element: <LandmarkAdd />,
    },
    {
      path: "/public/landmark/edit/:id",
      element: <LandmarkEdit />,
    },
    {
      path: "/public/zone",
      element: <Zone />,
    },
    {
      path: "/public/zone/add",
      element: <ZoneAdd />,
    },
    {
      path: "/public/zone/edit/:id",
      element: <ZoneEdit />,
    },
    {
      path: "/hospital",
      element: <Hospital />,
    },
    {
      path: "/hospital/add",
      element: <HospitalAdd />,
    },
    {
      path: "/hospital/edit/:id",
      element: <HospitalEdit />,
    },
    {
      path: "/responder",
      element: <Responder />,
    },
    {
      path: "/responder/add",
      element: <ResponderAdd />,
    },
    {
      path: "/responder/edit/:id",
      element: <ResponderEdit />,
    },
    {
      path: "/admin/user-manage",
      element: <Users />,
    },

    {
      path: "/admin/user-manage/add",
      element: <AddUserType />,
    },
    {
      path: "/admin/user-manage/edit/:id",
      element: <UpdateUser />,
    },
    {
      path: "/admin/user-access-control/:id",
      element: <AccessControl />,
    },
    {
      path: "/admin/role-management",
      element: <ViewRole />,
    },
    {
      path: "/admin/role-management/add",
      element: <AddRole />,
    },
    {
      path: "/admin/role-management/edit/:id",
      element: <EditRole />,
    },
    {
      path: "/admin/role-management/view",
      element: <TypeOfControl />,
    },
    {
      path: "/incident-report/:id/incident-form",
      element: <IncidentForm />,
    },
    {
      path: "/incident-report/:id/:serial/incident-view",
      element: <IncidentView />,
    },
    {
      path: "/incident-report/:id/incident-edit",
      element: <IncidentFormEdit />,
    },
    {
      path: "/incident-report/profile/:id/patient-form",
      element: <PatientForm />,
    },
    {
      path:
        "/incident-report/profile/:iid/patient-view/:id/:incidentSL/:patientSL",
      element: <PatientView />,
    },
    {
      path: "/incident-report/profile/:id/:SL",
      element: <IncidentProfile />,
    },
    {
      path: "/incident-report/profile/:iid/patient-form-edit/:id",
      element: access.includes("Report-Edit") ? (
        <PatientFormEdit />
      ) : (
        <Notfound />
      ),
    },
    {
      path: "/403",
      element: <Notfound />,
    },
    {
      path: "/incident-report/profile/:iid/hospital-patient-info/:id/",
      element:
        access.includes("Patient-addExtraDetails") ||
        (access.includes("Patient-View") &&
          !access.includes("Patient-addExtraDetails")) ? (
          <PatientInformation />
        ) : (
          <Notfound />
        ),
    },
  ],
};

export default MainRoutes;
