import { useState } from 'react';

// material-ui
import { Button, Step, Stepper, StepLabel, Stack, Typography } from '@mui/material';

// project imports

import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import InjuredCount from './InjuredCount';
import InjuredLocation from './InjuredLocation';

// step options
const steps = ['Injury Information', 'Location'];

function getStepContent({activeStep, handleNext, handleBack, setErrorIndex, incidentInfo, setIncidentInfo}:any) {
    switch (activeStep) {
        case 0:
            return (
                <InjuredCount
                    handleNext={handleNext}
                    setErrorIndex={setErrorIndex}
                    incidentInfo={incidentInfo}
                    setIncidentInfo={setIncidentInfo}
                />
            );
        case 1:
            return (
                <InjuredLocation
                />
            );
        default:
            throw new Error('Unknown step');
    }
}

// ==============================|| FORMS WIZARD - BASIC ||============================== //

const ValidationWizard = () => {
    const [activeStep, setActiveStep] = useState(0);
    const [incidentInfo, setIncidentInfo] = useState({});
    const [errorIndex, setErrorIndex] = useState(null);

    const handleNext = () => {
        setActiveStep(activeStep + 1);
        setErrorIndex(null);
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <MainCard title="Validation">
            <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                {steps.map((label, index) => {
                    const labelProps:any = {};

                    if (index === errorIndex) {
                        labelProps.optional = (
                            <Typography variant="caption" color="error">
                                Error
                            </Typography>
                        );

                        labelProps.error = true;
                    }

                    return (
                        <Step key={label}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
            <>
                {activeStep === steps.length ? (
                    <>
                        <Typography variant="h5" gutterBottom>
                            Thank you for your order.
                        </Typography>
                        <Typography variant="subtitle1">
                            Your order number is #2001539. We have emailed your order confirmation, and will send you an update when your
                            order has shipped.
                        </Typography>
                        <Stack direction="row" justifyContent="flex-end">
                            <AnimateButton>
                                <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => {
                                        setIncidentInfo({});
                                        setActiveStep(0);
                                    }}
                                    sx={{ my: 3, ml: 1 }}
                                >
                                    Reset
                                </Button>
                            </AnimateButton>
                        </Stack>
                    </>
                ) : (
                    <>
                        {getStepContent(
                            {activeStep,
                            handleNext,
                            handleBack,
                            setErrorIndex,
                            incidentInfo,
                            setIncidentInfo}
                        )}
                        {activeStep === steps.length - 1 && (
                            <Stack direction="row" justifyContent={activeStep !== 0 ? 'space-between' : 'flex-end'}>
                                {activeStep !== 0 && (
                                    <Button onClick={handleBack} sx={{ my: 3, ml: 1 }}>
                                        Back
                                    </Button>
                                )}
                                {activeStep === steps.length + 1 &&
                                <AnimateButton>
                                    <Button variant="contained" onClick={handleNext} sx={{ my: 3, ml: 1 }}>
                                        
                                    </Button>
                                </AnimateButton>
                                }
                            </Stack>
                        )}
                    </>
                )}
            </>
        </MainCard>
    );
};

export default ValidationWizard;
