import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Wrapper from "components/Card/Wrapper";
import FileInput from "components/common/FileInput";
import Required from "components/common/Required";
import { Formik } from "formik";
import { Deserializer } from "jsonapi-serializer";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SNACKBAR_OPEN } from "store/actions";
import { gridSpacing } from "store/constant";
import Loader from "ui-component/Loader";
import axiosServices from "utils/axiosServices";
import { serializeValidData } from "utils/Helpers";
import langString from "utils/langString";
import * as Yup from "yup";

export default function EditService() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [details, setDetails] = useState<any>(null);
  const param = useParams();



  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const postData = { uuid: param.id }
        
        const response = await axiosServices.post(
          `zone/get-all`,
          postData
        );
        if (response.status === 200) {
          setDetails(response.data.zoneList[0]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    init();
  }, [param.id]);

  return (
    <Wrapper
      title={`${langString("edit")} ${langString("zone")}`}
      backLink="/public/zone"
    >
      {loading && <Loader />}
      {details && (
        <Formik
          initialValues={{
            name: details.name,
            latitude: details.latitude,
            longitude: details.longitude,
            description: details.description,
            submit: null,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Name is required"),
            longitude: Yup.string().required("Longitude is required"),
            latitude: Yup.string().required("Latitude is required"),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            const body: any = {
              uuid: details.uuid,
              name: values.name,
              latitude: values.latitude,
              longitude: values.longitude,
              description: values.description
            };

            try {
              setLoading(true);
              const postData = serializeValidData("zones", {zone:body});
              const response = await axiosServices.post(
                "zone/update",
                postData
              );
              if (response.status === 200) {
                dispatch({
                  type: SNACKBAR_OPEN,
                  open: true,
                  message: "Zone Updated Successfully",
                  variant: "alert",
                  alertSeverity: "success",
                });
                navigate("/public/zone", { replace: true });
              }
              setLoading(false);
            } catch (err: any) {
              dispatch({
                type: SNACKBAR_OPEN,
                open: true,
                message: "Zone Updated Failed",
                variant: "alert",
                alertSeverity: "success",
              });
              setStatus({ success: false });
              setLoading(false);
              // setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
            <Box sx={{ border: "1px solid #EEE", padding: 3, borderRadius: 3 }}>
              <Grid container spacing={gridSpacing}>
                <Grid item xs={6} md={3}>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: 2, textAlign: "right" }}
                  >
                    Zone Name <Required />
                  </Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  <FormControl
                    fullWidth
                    error={Boolean(touched.name && errors.name)}
                  >
                    <TextField
                      id="outlined-adornment-name-user-create"
                      type="text"
                      label={"Zone Name"}
                      value={values.name}
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    {touched.name && errors.name && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-name-user-create"
                      >
                        {errors.name}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={gridSpacing} sx={{ marginTop: "5px" }}>
                <Grid item xs={6} md={3}>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: 2, textAlign: "right" }}
                  >
                    {langString("Description")}
                  </Typography>
                </Grid>
                <Grid item xs={6} md={7}>
                  <FormControl fullWidth>
                    <TextField
                      id="outlined-adornment-name-user-create"
                      type="text"
                      label={langString("Description")}
                      value={values.description}
                      name="description"
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                    
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={gridSpacing} sx={{ marginTop: "5px" }}>
                <Grid item xs={6} md={3}>
                  <Typography
                    variant="h5"
                    sx={{ marginTop: 2, textAlign: "right" }}
                  >
                    {langString("Location Mark")} <Required />
                  </Typography>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControl fullWidth error={Boolean(touched.latitude && errors.latitude)}>
                    <TextField
                      label={langString("Latitude")}
                      id="outlined-adornment-quantity-user-create"
                      value={values.latitude}
                      type="text"
                      name="latitude"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                    />
                    {touched.latitude && errors.latitude && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-name-user-create"
                      >
                        {errors.latitude}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4} md={3}>
                  <FormControl fullWidth>
                    <TextField
                      label={langString("Longitude")}
                      id="outlined-adornment-quantity-user-create"
                      value={values.longitude}
                      type="text"
                      name="longitude"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      required
                    />
                    {touched.longitude && errors.longitude && (
                      <FormHelperText
                        error
                        id="standard-weight-helper-text-name-user-create"
                      >
                        {errors.longitude}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>


              <Grid container spacing={gridSpacing} sx={{ marginTop: "5px" }}>


              </Grid>

              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>{errors.submit}</FormHelperText>
                </Box>
              )}
              <Box sx={{ textAlign: "right", paddingTop: 3 }}>
                <Button
                  color="warning"
                  disabled={isSubmitting}
                  size="large"
                  type="button"
                  onClick={() => navigate("/public/zone", { replace: true })}
                  variant="contained"
                  sx={{ marginRight: 2 }}
                >
                  {langString("cancel")}
                </Button>
                <Button
                  color="primary"
                  disabled={isSubmitting}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {langString("save")}
                </Button>
              </Box>
            </Box>
          </form>
          )}
        </Formik>
      )}
    </Wrapper>
  );
}
