import { Typography } from "@mui/material";
import React from "react";

const Notfound = () => {
  return (
    <>
      <Typography align="center" variant="h1" color="error">
        Traumalink
      </Typography>
    </>
  );
};

export default Notfound;
