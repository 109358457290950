import { useState, useEffect } from "react";

const useRowsPerPage = (pageName: string) => {
  const [currentRow, setPage] = useState(10);

  useEffect(() => {
    const storedRow = sessionStorage.getItem(pageName);
    if (storedRow !== null) {
      setPage(parseInt(storedRow));
    }

    const handleBeforeUnload = () => {
      sessionStorage.removeItem(pageName);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [pageName]);

  useEffect(() => {
    sessionStorage.setItem(pageName, String(currentRow));
  }, [pageName, currentRow]);

  const handleRowPerPageChange = (newRow: number) => {
    setPage(newRow);
  };

  return [currentRow, handleRowPerPageChange] as const;
};

export default useRowsPerPage;
