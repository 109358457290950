import { useState, useEffect } from 'react';

const usePagination = (pageName: string) => {
  const [currentPage, setPage] = useState(0);

  useEffect(() => {
    const storedPage = sessionStorage.getItem(pageName);
    if (storedPage !== null) {
      setPage(parseInt(storedPage));
    }

    const handleBeforeUnload = () => {
      sessionStorage.removeItem(pageName);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [pageName]);

  useEffect(() => {
    sessionStorage.setItem(pageName, String(currentPage));
  }, [pageName, currentPage]);

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  return [currentPage, handlePageChange] as const;
};

export default usePagination;