// import { zoneModule, createPoint, editPoint, deletePoint, viewPoint, landmarkModule, userModule, responderModule, roleModule, hospitalModule, accessControlModule, incidentModule, locationModule, reportModule, patientModule } from 'types/constants';
import Cookies from "universal-cookie";

const cookie = new Cookies();
const access = cookie.get("userPermisson");

// export const zoneModule = "Zone";
// export const landmarkModule = "Landmark";
// export const userModule = "User";
// export const responderModule = "Responder";
// export const roleModule = "Role";
// export const hospitalModule = "Hospital";
// export const accessControlModule = "AccessControl";
// export const incidentModule = "Incident";
// export const locationModule = "Location";
// export const reportModule = "Report";
// export const patientModule = "Patient";

// export const viewPoint = "View";
// export const createPoint = "Create";
// export const editPoint = "Edit";
// export const deletePoint = "Delete";

export const permission = () => {
  const arr: any[] = [];
  access?.map((item: any) => {
    arr.push(item.name);
  });
  return arr;
};
