// third-party
// assets
import {
  IconHome,
  IconFileAnalytics,
  IconMap2,
  IconBuildingHospital,
  IconUsers,
  IconUser,
  IconCurrentLocation,
  IconMapPin,
  IconLockAccess,
  IconUserCheck,
  IconFingerprint
} from "@tabler/icons"; 
import { FormattedMessage } from "react-intl";
import { permission } from "utils/permission";

// constant
const icons = {
  IconHome,
  IconFileAnalytics,
  IconMap2,
  IconBuildingHospital,
  IconUsers,
  IconUser,
  IconCurrentLocation,
  IconMapPin,
  IconLockAccess,
  IconUserCheck,
  IconFingerprint
};
// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //
const access = permission()

const other = {
  id: "sample-docs-roadmap",
  type: "group",
  children: [
    {
      id: "home",
      title: <FormattedMessage id="Home" />,
      type: "item",
      icon: icons.IconHome,
      url: "/",
      breadcrumbs: true,
      permission: access.includes('Incident-Create')
    },
    {
      id: "reports",
      title: <FormattedMessage id="Reports" />,
      type: "collapse",
      icon: icons.IconFileAnalytics,
      permission: access.includes('Report-View') || access.includes('Incident-View'),
      children:[
        {
          id: "Incident Reports",
          title: <FormattedMessage id="Incident Reports" />,
          type: "item",
          icon: icons.IconFileAnalytics,
          url: "/reports/incidents-report",
          breadcrumbs: true,
          permission: access.includes('Incident-View'),
        },
        {
          id: "Response Times",
          title: <FormattedMessage id="Response Times" />,
          type: "item",
          icon: icons.IconFileAnalytics,
          url: "/reports/response-times",
          breadcrumbs: true,
          permission: access.includes('Report-View')
        },
        {
          id: "Patient Age and gender",
          title: <FormattedMessage id="Patient Age and gender" />,
          type: "item",
          icon: icons.IconFileAnalytics,
          url: "/reports/patient-age-gender",
          breadcrumbs: true,
          permission: access.includes('Report-View')
        },
        {
          id: "Time from crash to Hospital",
          title: <FormattedMessage id="Time from crash to Hospital" />,
          type: "item",
          icon: icons.IconFileAnalytics,
          url: "/reports/time-from-crash-hospital",
          breadcrumbs: true,
          permission: access.includes('Report-View')
        },
        {
          id: "Injury Assessment",
          title: <FormattedMessage id="Injury Assessment" />,
          type: "item",
          icon: icons.IconFileAnalytics,
          url: "/reports/injury-assessment",
          breadcrumbs: true,
          permission: access.includes('Report-View')
        }
      ]
    },
    {
      id: "location",
      title: <FormattedMessage id="Location" />,
      icon: icons.IconMap2,
      type: "collapse",
      permission: access.includes('Landmark-View') || access.includes('Zone-View'),
      children: [
        {
          id: "manage-zone",
          title: <FormattedMessage id="Manage Zone" />,
          type: "item",
          icon:icons.IconCurrentLocation,
          url: "/public/zone",
          breadcrumbs: true,
          permission: access.includes('Zone-View')
        },
        {
          id: "manage-landmark",
          title: <FormattedMessage id="Manage Landmark" />,
          type: "item",
          icon:icons.IconMapPin,
          url: "/public/landmark",
          breadcrumbs: true,
          permission: access.includes('Landmark-View')
        },
      ],
    },
    {
      id: "hospital",
      title: <FormattedMessage id="Hospital" />,
      type: "item",
      icon: icons.IconBuildingHospital,
      url: "/hospital",
      breadcrumbs: true,
      permission: access.includes('Hospital-View')
    },
    {
      id: "responder",
      title: <FormattedMessage id="Responder" />,
      type: "item",
      icon:icons.IconUsers,
      url: "/responder",
      breadcrumbs: true,
      permission: access.includes('Responder-View')
    },
    {
      id: "admin",
      title: <FormattedMessage id="Admin" />,
      icon: icons.IconUser,
      type: "collapse",
      permission: access.includes('User-View') || access.includes('Role-View') || access.includes('AccessControl-View') ,
      children: [
        {
          id: "user-manage",
          title: <FormattedMessage id="User Manage" />,
          type: "item",
          icon:icons.IconUserCheck,
          url: "/admin/user-manage",
          breadcrumbs: true,
          permission: access.includes('User-View')
        },
        {
          id: "user-role-management",
          title: <FormattedMessage id="Role Management" />,
          type: "item",
          icon: icons.IconFingerprint,
          url: "/admin/role-management",
          breadcrumbs: true,
          permission: access.includes('Role-View')
        },
        {
          id: "user-access-control",
          title: <FormattedMessage id="User Access Control" />,
          type: "item",
          icon: icons.IconLockAccess,
          url: "/admin/role-management/view",
          breadcrumbs: true,
          permission: access.includes('AccessControl-View')
        },
      ],
    },
  ],
};

export default other;
