import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Wrapper from "components/Card/Wrapper";
import BasicDataTable, { ColumnType } from "components/common/BasicDataTable";
import ConfirmButton from "components/common/ConfirmButton";
import SearchBox from "components/common/SearchBox";
import { Deserializer } from "jsonapi-serializer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SNACKBAR_OPEN } from "store/actions";
import Loader from "ui-component/Loader";
import axiosServices from "utils/axiosServices";
import { serializeValidData } from "utils/Helpers";
import SearchIcon from "@mui/icons-material/Search";
import langString from "utils/langString";
import { RootState } from "types/vehicaleField";
import Cookies from "universal-cookie";
import { permission } from "utils/permission";
// import { zoneAdd, zoneEdit } from "utils/permission";

export default function ServiceList() {
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<any[]>([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState<string>("");
  const [startIndex, setStartIndex] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const access = permission();

  const Columns: ColumnType[] = [
    // {
    //   header: "SL No",
    //   accessor: "id",
    //   content: (item: any) => (
    //     <Typography>{rows.indexOf(item) + startIndex + 1}</Typography>
    //   ),
    // },
    {
      header: "Zone ID",
      accessor: "id",
      content: (item: any) => <Typography>{item.id}</Typography>,
    },
    { header: "Zone Name", accessor: "name" },
    {
      header: "Latitude",
      accessor: "latitude",
      content: (item: any) => <Typography>{item.latitude}</Typography>,
    },
    {
      header: "Longitude",
      accessor: "longitude",
      content: (item: any) => <Typography>{item.longitude}</Typography>,
    },

    {
      header: "Action",
      accessor: "action",
      content: (item: any) => (
        <Box display="flex" sx={{ justifyContent: "space-around" }}>
          {access.includes("Zone-Edit") && (
            <Button
              component={Link}
              variant="contained"
              color="secondary"
              to={`/public/zone/edit/${item.uuid}`}
              size="small"
              sx={{ marginLeft: 0.5 }}
            >
              Edit
            </Button>
          )}

          {access.includes("Zone-Delete") && (
            <ConfirmButton
              subTitle={`Delete Zone Location: ${item.name}`}
              confirmed={() => deleteListItem(item.uuid)}
            />
          )}
        </Box>
      ),
    },
  ];

  const deleteListItem = async (uuid: string) => {
    if (uuid) {
      setLoading(true);
      try {
        const postData = serializeValidData("zones", { zone: { uuid: uuid } });
        const response = await axiosServices.post(`zone/delete`, postData);
        if (response.status === 200) {
          dispatch({
            type: SNACKBAR_OPEN,
            open: true,
            message: "Zone deleted successfully.",
            variant: "alert",
            alertSeverity: "success",
          });
          const allRows = [...rows];
          const filtered = allRows.filter((item: any) => item.uuid !== uuid);

          setRows(filtered);
          setLoading(false);
        }
      } catch (error: any) {
        setLoading(false);
        dispatch({
          type: SNACKBAR_OPEN,
          open: true,
          message: "This zone is used",
          variant: "alert",
          alertSeverity: "error",
        });
      }
    }
  };

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const response = await axiosServices.post(
          `zone/get-all?limit=${rowsPerPage}&currentPage=${page + 1}`
        );
        if (response.status === 200) {
          if (response.data) {
            setCount(response.data.paginationInfo.totalData);
            setRows(response.data?.zoneList);
            setStartIndex(response.data?.paginationInfo.startIndex);
          }
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    init();
  }, [page, rowsPerPage]);

  const handleSearch = (value: string) => {
    setSearch(value);
    const body = { searchString: value };
    axiosServices
      .post("zone/search", body)
      .then((response) => {
        setCount(response.data.paginationInfo.totalData);
        setRows(response.data?.zoneList);
        setStartIndex(response.data?.paginationInfo.startIndex);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Wrapper
      title={langString("Zone")}
      addLink="/public/zone/add"
      permission={access.includes("Zone-Create")}
    >
      {loading && <Loader />}
      <Box sx={{ marginTop: 1 }}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            sx={{ marginBottom: 2, textAlign: "right" }}
          >
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search Zone"
              size="small"
            />
          </Grid>
        </Grid>
        {access.includes("Zone-View") === true ? (
          <BasicDataTable
            columns={Columns}
            rows={rows}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={(value: number) => setPage(value)}
            setRowsPerPage={(value: number) => setRowsPerPage(value)}
          />
        ) : (
          <Typography variant="h1" align="center" color="error">
            Access Denied !
          </Typography>
        )}
      </Box>
    </Wrapper>
  );
}
