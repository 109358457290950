import { Grid, Paper, Typography } from "@mui/material";
import Wrapper from "components/Card/Wrapper";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { gridSpacing } from "store/constant";
import axiosServices from "utils/axiosServices";
import { permission } from "utils/permission";

const PatientView = () => {
  const [details, setDetails] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const param = useParams();
  const access = permission();

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      try {
        const postData =
          access.includes("Patient-addExtraDetails") &&
          !access.includes("Patient-Create")
            ? { uuid: param.id, incidentUuid: param.iid, outsider: true }
            : { uuid: param.id, incidentUuid: param.iid };

        const response = await axiosServices.post(`patient/get-all`, postData);
        if (response.status === 200) {
          setDetails(response.data.patientList[0]);
          console.log(response.data.patientList[0]);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    };
    init();
  }, [param.id]);

  return (
    <Wrapper
      title={`Patient no. ${param.patientSL} of Incident no. ${param.incidentSL} Profile`}
      backLink={`/incident-report/profile/${param.iid}`}
    >
      <Paper elevation={2} sx={{ padding: 3, margin: 2 }}>
        <Typography sx={{ marginBottom: 1 }} variant="h4">
          Patient Information
        </Typography>
        <Grid container spacing={gridSpacing} sx={{ px: 5, marginTop: "5px" }}>
          <Grid item xs={6} md={2}>
            <Typography sx={{ textAlign: "left", fontWeight: "bold" }}>
              Patient ID:
            </Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.patient_id}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Patient Name:
            </Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.name}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={gridSpacing} sx={{ px: 5, marginTop: "5px" }}>
          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Age:
            </Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.age_year}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Gender
            </Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.gender
                ? details?.gender.charAt(0).toUpperCase() +
                  details?.gender.slice(1)
                : ""}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={gridSpacing} sx={{ px: 5, marginTop: "5px" }}>
          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Type of Road User:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.type_of_vehicle_user}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Location in vehicle:
            </Typography>
          </Grid>

          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.location_in_vehicle}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={gridSpacing} sx={{ px: 5, marginTop: "5px" }}>
          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Injury Severity Rating:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.injury_severity}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ padding: 3, margin: 2 }}>
        <Typography sx={{ marginBottom: 1 }} variant="h4">
          Treatment Outcomes
        </Typography>

        <Grid
          container
          alignItems="center"
          spacing={gridSpacing}
          sx={{ px: 5, marginTop: "5px" }}
        >
          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Did the patient receive treatment at the scene:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.treatment_at_scene ? "Yes" : "No"}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Treated by:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.treated_by}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          spacing={gridSpacing}
          sx={{ px: 5, marginTop: "5px" }}
        >
          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              If not treated at scene, why not:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.reason_for_no_treatment}
            </Typography>
          </Grid>
          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Clinical outcome:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.clinical_outcome}
            </Typography>
          </Grid>
        </Grid>
      </Paper>

      <Paper elevation={2} sx={{ padding: 3, margin: 2 }}>
        <Typography sx={{ marginBottom: 1 }} variant="h4">
          If went to the Hospital
        </Typography>

        <Grid
          container
          alignItems="center"
          spacing={gridSpacing}
          sx={{ px: 5, marginTop: "5px" }}
        >
          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Time (in minutes) between crash and arrival at hospital:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.arrival_delay && `${details?.arrival_delay} mins`}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Transport Vehicle:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.patient_transport}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          spacing={gridSpacing}
          sx={{ px: 5, marginTop: "5px" }}
        >
          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Who accompanied the patient:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.patient_company}
            </Typography>
          </Grid>

          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Hospital Type:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.hospital_type}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          spacing={gridSpacing}
          sx={{ px: 5, marginTop: "5px" }}
        >
          <Grid item xs={6} md={2}>
            <Typography sx={{ fontWeight: "bold", textAlign: "left" }}>
              Appropriate Receiving Hospital:
            </Typography>
          </Grid>
          <Grid item xs={6} md={4}>
            <Typography sx={{ textAlign: "left", color: "#357e2a" }}>
              {details?.appropriate_hospital ? "Yes" : "No"}
            </Typography>
          </Grid>
        </Grid>
      </Paper>
    </Wrapper>
  );
};

export default PatientView;
